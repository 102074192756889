<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.onjn') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            <label>{{ $t('sportsbets.startDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            <label>{{ $t('sportsbets.endDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-6 p-md-7 p-text-right"><Button type="button" :loading="isReportLoading" :label="$t('buttons.show')" class="p-button-raised p-button-success" @click="showReport()" /></div>
                    <div class="p-col-6 p-md-1 p-text-right">
                        <Button type="button" :label="$t('buttons.saveXls')" :loading="isReportDownloading" icon="pi pi-download" class="p-button-raised p-button-info" @click="saveXls()" />
                    </div>
                    <transition-group name="p-message" tag="div" class="p-col-12">
                        <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ $t('alerts.generalError') }}</Message>
                    </transition-group>
                </div>
                <div class="p-grid" v-if="showReportData">
                    <div class="p-col-6 p-md-3">
                        <div class="card overview-box p-d-flex p-flex-column p-pt-2">
                            <div class="p-d-flex p-ai-center muted-text">
                                <h6 class="p-m-0">{{ $t('reports.tickets') }}</h6>
                            </div>
                            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                <div class="p-d-flex p-flex-column">
                                    <span class="p-mb-1 fs-xlarge">{{ stats.allCount }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-6 p-md-3">
                        <div class="card overview-box p-d-flex p-flex-column p-pt-2">
                            <div class="p-d-flex p-ai-center muted-text">
                                <h6 class="p-m-0">{{ $t('reports.stornoTickets') }}</h6>
                            </div>
                            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                <div class="p-d-flex p-flex-column">
                                    <span class="p-mb-1 fs-xlarge">{{ stats.stornoCount }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-6 p-md-3">
                        <div class="card overview-box p-d-flex p-flex-column p-pt-2">
                            <div class="p-d-flex p-ai-center muted-text">
                                <h6 class="p-m-0">{{ $t('reports.stornoAmount') }}</h6>
                            </div>
                            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                <div class="p-d-flex p-flex-column">
                                    <span class="p-mb-1 fs-xlarge">{{ formatCurrency(stats.stornoSum) + ' ' + $t('general.currency') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-6 p-md-3">
                        <div class="card overview-box p-d-flex p-flex-column p-pt-2">
                            <div class="p-d-flex p-ai-center muted-text">
                                <h6 class="p-m-0">{{ $t('reports.voidEvents') }}</h6>
                            </div>
                            <div class="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                                <div class="p-d-flex p-flex-column">
                                    <span class="p-mb-1 fs-xlarge">{{ parseFloat(stats.voidEventsCount) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoReportsApi } from '../../../service/BoReportsApi';
import { BoSaveReportsApi } from '../../../service/BoSaveReportsApi';
export default {
    name: 'report',
    data() {
        return {
            stats: {},
            BoReportsApi: new BoReportsApi(),
            BoSaveReportsApi: new BoSaveReportsApi(),
            isReportLoading: false,
            isReportDownloading: false,
            showReportData: false,
            startDateModel: null,
            endDateModel: null,
            errorKeyCount: 0,
            showError: false,
        };
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-' + day + 'T00:00';
        var endDateString = year + '-' + month + '-' + day + 'T23:59';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
    },
    methods: {
        showReport() {
            this.showReportData = false;
            this.isReportLoading = true;
            this.BoReportsApi.getStornoStats(this.formatEnDate(this.startDateModel), this.formatEnDate(this.endDateModel))
                .then((reportResponse) => {
                    this.stats = reportResponse.data;
                    this.isReportLoading = false;
                    this.showReportData = true;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorKeyCount++;
                    this.isReportLoading = false;
                });
        },
        saveXls() {
            this.isReportDownloading = true;
            this.BoSaveReportsApi.getStornoStats(this.formatEnDate(this.startDateModel), this.formatEnDate(this.endDateModel))
                .then((response) => {
                    this.isReportDownloading = false;
                    let blob = new Blob([response.data], { type: 'application/xls' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'OnjnStornoReport_' + this.formatEnDateAndTime(this.startDateModel) + '_' + this.formatEnDateAndTime(this.endDateModel) + '.xlsx';
                    link.click();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorKeyCount++;
                    this.isReportDownloading = false;
                });
        },
        debounceSearch(event) {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.term = event.target.value;
            }, this.debounceTime);
        },
        onPage(event) {
            this.crtPage = event.page + 1;
        },
    },
};
</script>